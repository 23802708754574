export const promptMature = async (censor = true) => {
  const appStore = useAppStore();
  const authStore = useAuthStore();
  const { t: translate } = useNuxtApp().$i18n.global;
  await authStore.waitUntilRefreshIsComplete();

  const loggedIn = !!authStore?.user;

  const promptLoggedInMature = async () =>
    await appStore?.prompt("Warning", {
      detail: !!authStore?.user?.profile.age_verified
        ? translate("site.chapter.modalEnableMature")
        : translate("site.chapter.modalConfirmAge"),
      icon: "wink",
      buttons: {
        settings: {
          buttonType: "primary",
          buttonText: !!authStore?.user?.profile.age_verified
            ? translate("site.chapter.buttonGoToSettings")
            : translate("site.chapter.buttonConfirmAge"),
        },
        cancel: {
          buttonType: "secondary",
          buttonText: translate("site.chapter.buttonBackToTitle"),
        },
      },
      preventOutsideClicks: true,
      censorBackground: censor,
    });

  const promptGuestMature = async () =>
    await appStore?.prompt("Warning", {
      detail: translate("site.chapter.modalEnableMatureGuest"),
      icon: "wink",
      buttons: {
        settings: {
          buttonType: "primary",
          buttonText: translate("site.chapter.buttonLogin"),
        },
        cancel: {
          buttonType: "secondary",
          buttonText: translate("site.chapter.buttonBackToTitle"),
        },
      },
      preventOutsideClicks: true,
      censorBackground: censor,
    });

  return !loggedIn ? await promptGuestMature() : await promptLoggedInMature();
};

export const promptSubscriptionLocked = async () => {
  const appStore = useAppStore();
  return (
    (await appStore?.prompt(
      "You need to be subscribed to the organization to access this chapter.",
      {
        icon: "okay",
        forceCloseKey: "cancel",
        buttons: {
          cancel: {
            buttonType: "secondary",
            buttonText: "Close",
          },
          subscribe: {
            buttonType: "primary",
            buttonText: "Subscribe",
          },
        },
      },
    )) ?? "cancel"
  );
};
