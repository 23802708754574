import { ReadMarker } from "~/src/api";
import { useNuxtApp } from "#app";

const debugStyle =
  "color:white;background-color:rgb(5,170,240);padding:2px 4px;border-radius:4px;";

export const useReadMarkers = (titleId?: string) => {
  const databaseRefreshIndex = useState("database-refresh-index", () => 0);
  const dayjs = useDayjs();
  const nuxtApp = useNuxtApp();
  const authStore = nuxtApp.$auth();

  const namidb = process.client ? import("../src/namidb") : null;

  // marker actions
  const markChaptersRead = async (readIds: string[]) => {
    if (!titleId || !namidb) return null;
    const { NamiCacheDatabase } = await namidb;
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    NamiCacheDatabase.readMarkers.bulkPut(
      readIds.map((id) => ({
        id,
        read: true,
        relatedTitleId: titleId,
        timestamp: now,
      })),
    );
    console.debug(
      "%c%s",
      debugStyle,
      "ReadMarkers",
      "Marked ids as read:",
      readIds,
    );
    databaseRefreshIndex.value++;

    // we want this to be a no-op here so we dont have to handle it elsewhere
    const token = await authStore?.getToken();
    if (!token) return null;

    return ReadMarker.setReadMarkers(token, titleId, readIds, [], now);
  };
  const markChaptersUnread = async (unreadIds: string[]) => {
    if (!titleId || !namidb) return null;
    const { NamiCacheDatabase } = await namidb;
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    NamiCacheDatabase.readMarkers.bulkPut(
      unreadIds.map((id) => ({
        id,
        read: false,
        relatedTitleId: titleId,
        timestamp: now,
      })),
    );
    console.debug(
      "%c%s",
      debugStyle,
      "ReadMarkers",
      "Marked ids as unread:",
      unreadIds,
    );
    databaseRefreshIndex.value++;

    const token = await authStore?.getToken();
    if (!token) return null;

    return ReadMarker.setReadMarkers(token, titleId, [], unreadIds, now);
  };
  const markChaptersBatch = async (
    readIds: string[] = [],
    unreadIds: string[] = [],
  ) => {
    if (!titleId || !namidb) return null;
    const { NamiCacheDatabase } = await namidb;

    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    const unread = unreadIds.map((id) => ({
      id,
      read: false,
      relatedTitleId: titleId,
      timestamp: now,
    }));
    const read = readIds.map((id) => ({
      id,
      read: true,
      relatedTitleId: titleId,
      timestamp: now,
    }));
    NamiCacheDatabase.readMarkers.bulkPut(read.concat(unread));
    console.debug(
      "%c%s",
      debugStyle,
      "ReadMarkers",
      "Marked ids as read:",
      readIds,
    );
    console.debug(
      "%c%s",
      debugStyle,
      "ReadMarkers",
      "Marked ids as unread:",
      unreadIds,
    );

    const token = await authStore?.getToken();
    if (!token) return null;

    return ReadMarker.setReadMarkers(token, titleId, readIds, unreadIds, now);
  };

  // query actions
  const isChapterRead = async (chapterId: string) => {
    const isLoggedIn = await authStore?.isLoggedIn();

    if (!namidb || !isLoggedIn) return false;

    const { NamiCacheDatabase } = await namidb;
    const res = await NamiCacheDatabase.readMarkers.get(chapterId);

    return !!res?.read;
  };
  const refreshDatabaseMarkersForTitle = async () => {
    if (!titleId || !namidb) return null;
    const { NamiCacheDatabase } = await namidb;

    const token = await authStore?.getToken();
    if (!token) return;

    const result = await ReadMarker.getReadMarkersForTitle(titleId, token);
    const mapped = Object.entries(result.attributes.readChapterIds).map(
      ([id, timestamp]) => ({
        id: id as string,
        read: true,
        timestamp: timestamp as string | undefined,
        relatedTitleId: titleId,
      }),
    );

    const allTrackedChaptersForTitle = await NamiCacheDatabase.readMarkers
      .where("relatedTitleId")
      .equals(titleId)
      .toArray();
    const databaseMarkersToUnmark = allTrackedChaptersForTitle
      .filter(
        (tracked) => !mapped.some((newMarkers) => newMarkers.id === tracked.id),
      )
      .map((marker) => ({ ...marker, read: false }));

    console.debug(
      "%c%s",
      debugStyle,
      "ReadMarkers",
      "Updated database with rows:",
      mapped.concat(databaseMarkersToUnmark),
    );
    await NamiCacheDatabase.readMarkers.bulkPut(
      mapped.concat(databaseMarkersToUnmark),
    );
    databaseRefreshIndex.value++;
  };

  return {
    // these functions do nothing if no titleId is passed
    markChaptersRead,
    markChaptersUnread,
    markChaptersBatch,
    refreshDatabaseMarkersForTitle,

    // these are read-only
    databaseRefreshIndex: readonly(databaseRefreshIndex),
    isChapterRead,
  };
};
