<template>
  <div
    class="chapter-card__wrap"
    :class="{ transparent, read: isRead && loggedIn }"
  >
    <component
      :is="noLink || !isUnlocked || userNotMature ? 'div' : TheNuxtLink"
      :key="parsedChapter.id"
      :to="parsedChapter.href"
      class="chapter-card relative"
      :class="{ small, 'x-small': xSmall, context: !!$slots.contextMenu }"
      @click="handleChapterClick"
    >
      <div class="chapter-card__preview relative rounded-md overflow-hidden">
        <AsyncImage
          class="w-full aspect-[3/2] object-cover"
          :src="parsedChapter.thumbnail || props.fallbackUrl"
        />
        <div
          v-if="isLocked && !hasAccess"
          class="absolute left-0 top-0 w-full h-full flex items-center justify-center bg-black/50"
        >
          <div class="p-1.5 bg-white/80 dark:bg-neutral-800/80 rounded-full">
            <IconLock class="block" />
          </div>
        </div>
      </div>
      <div
        class="chapter-card__title ml-2 mt-1 grid grid-cols-[auto_auto_1fr] gap-2 items-center"
      >
        <div
          v-if="(titleId && !isRead) || !loggedIn"
          class="mb-0.5 w-2 h-2 rounded-full inline-block bg-nami-comi-blue"
          :class="{ 'w-1.5 h-1.5': small }"
        />
        <p class="truncate">
          {{
            small
              ? parsedChapter.shortName.replace(/ - .+/, "")
              : parsedChapter.longName
          }}
        </p>
        <span
          v-if="isLocked"
          class="hidden sm:inline-flex flex-shrink-0 gap-1 sm:py-[0.125rem] h-4 sm:h-6 sm:w-max sm:px-1 items-center justify-center rounded-full"
          :class="{
            'border border-nami-comi-blue text-nami-comi-blue': !hasAccess,
            'border border-yellow-600 dark:border-yellow-400 text-yellow-600 dark:text-yellow-400':
              hasAccess,
          }"
        >
          <IconCrown v-if="hasAccess" :size="xSmall ? 12 : 16" />
          <IconLock v-else :size="xSmall ? 12 : 16" />
          <span
            v-if="!small && !xSmall"
            class="hidden sm:inline font-medium text-sm"
          >
            {{ hasAccess ? "Subscribed" : "Subscribers" }}
          </span>
        </span>
        <span
          v-if="parsedChapter.wip && showWip"
          class="font-medium text-sm uppercase bg-purple-400 text-white inline-block w-max px-2 rounded-full"
          >WIP</span
        >
      </div>
      <div class="chapter-card__options" v-if="!!$slots.contextMenu"></div>
      <div
        class="flex mx-2 mb-1 gap-1"
        style="grid-area: stats"
        :class="{
          'justify-between sm:justify-end': isLocked,
          'justify-end': !isLocked,
        }"
      >
        <div
          v-if="isLocked"
          class="sm:hidden sm:py-[0.125rem] flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 sm:px-1 flex gap-1 items-center justify-center rounded-full"
          :class="{
            'border border-nami-comi-blue text-nami-comi-blue': !hasAccess,
            'border border-yellow-600 dark:border-yellow-400 text-yellow-600 dark:text-yellow-400':
              hasAccess,
          }"
          style="grid-area: info"
        >
          <IconCrown v-if="hasAccess" :size="xSmall ? 12 : 16" />
          <IconLock v-else :size="xSmall ? 12 : 16" />
        </div>
        <div
          class="chapter-card__stats text-sm flex items-center responsive-gap whitespace-nowrap text-right"
        >
          <span
            v-if="!noLang"
            class="transition-colors select-none rounded-md text-xs font-bold uppercase bg-neutral-200 dark:bg-neutral-700 px-1 py-0.5"
          >
            {{ mappedLocale }}
          </span>
          <span
            v-if="!noStats"
            :title="statistics?.attributes.viewCount?.toString()"
          >
            <IconEye :size="16" />
            <span v-if="!statsLoading && statistics">
              {{ formatNumber(statistics.attributes.viewCount ?? 0) }}
            </span>
            <span v-else>...</span>
          </span>
          <span v-if="!noStats" :title="totalLikes.toString()">
            <IconHeart :size="16" />
            <span v-if="!statsLoading && statistics">
              {{ formatNumber(totalLikes) }}
            </span>
            <span v-else>...</span>
          </span>
          <span
            v-if="!noStats"
            :title="statistics?.attributes.commentCount?.toString()"
          >
            <IconMessageDots :size="16" />
            <span v-if="!statsLoading && statistics">
              {{ formatNumber(statistics.attributes.commentCount ?? 0) }}
            </span>
            <span v-else>...</span>
          </span>
          <span
            class="truncate"
            :title="new Date(parsedChapter.publishAt ?? '').toISOString()"
          >
            <IconClock :size="16" />
            <span>{{ publishAt }}</span>
          </span>
        </div>
      </div>
    </component>
    <div
      class="chapter-card__more-options absolute top-0.5 sm:top-1 right-0.5 sm:right-1"
    >
      <slot name="contextMenu">
        <ContextMenuList
          close-on-outside-click
          default-position="bottom-left"
          class="inline-block relative"
          :options="
            [
              {
                icon: isRead ? IconEyeOff : IconEye,
                name: isRead
                  ? translate('components.chapterCard.contextMenuMarkUnread')
                  : translate('components.chapterCard.contextMenuMarkRead'),
                action: () => {
                  if (!loggedIn) appStore?.openLoginRequiredModal();
                  else toggleRead();
                },
              },
              selectable && {
                icon: IconSelectAll,
                name: translate('components.chapterCard.contextMenuSelectAll'),
                action: () => {
                  if (!loggedIn) appStore?.openLoginRequiredModal();
                  else emit('selectAll');
                },
              },
            ].filter(onlyTruthys)
          "
        >
          <template v-slot="{ isOpen, open, close }">
            <NamiButton
              text
              :icon="IconDots"
              button-type="secondary"
              small
              class="w-6 h-6"
              @button-click="() => (isOpen ? close() : open())"
            />
          </template>
        </ContextMenuList>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Chapter,
  getRelationship,
  isExpanded,
  isRelationship,
  Organization,
  type ChapterEntity,
  type ChapterKind,
  type ChapterRelation,
  type OrganizationEntity,
  type OrganizationRelation,
  type VolumeKind,
  type PopulateRelationship,
} from "~/src/api";
import {
  IconDots,
  IconEye,
  IconMessageDots,
  IconEyeOff,
  IconHeart,
  IconClock,
  IconLock,
  IconCrown,
  IconSelectAll,
} from "@tabler/icons-vue";
import { parseChapterForDisplay } from "~/utils/display/chapter";
import { useLazyAsyncData } from "#app";
import ModalGatingNoAccess from "../modal/gating/ModalGatingNoAccess.vue";
import { useQuery } from "@tanstack/vue-query";
import { promptMature } from "~/src/reader/ux";

const TheNuxtLink = resolveComponent("TheNuxtLink");

interface Props {
  chapter: ChapterEntity | ChapterRelation;
  fallbackUrl: string;
  volumeKind?: VolumeKind;
  chapterKind?: ChapterKind;
  titleId?: string;
  transparent?: boolean;
  noStats?: boolean;
  noLang?: boolean;
  small?: boolean;
  xSmall?: boolean;
  noLink?: boolean;
  selectable?: boolean;
  organization?:
    | OrganizationEntity
    | PopulateRelationship<OrganizationRelation>;
  isLocked?: boolean;
  hasAccess?: boolean;
  showWip?: boolean;
  mature?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "navigate", id: string): void;
  (e: "selectAll"): void;
}>();
const route = useRoute();
const nuxtApp = useNuxtApp();
const translate = nuxtApp.$i18n.global.t;
const locale = nuxtApp.$i18n.global.locale;
const authStore = nuxtApp.$auth();
const settingsStore = nuxtApp.$settings();
const appStore = nuxtApp.$app();
const loggedIn = computed(() => authStore?.user);

if (isRelationship(props.chapter) && !props.organization) {
  throw new Error(
    "ChapterCard: must provide an organization when chapter is a relationship",
  );
}

const isUnlocked = computed(() => {
  return props.isLocked ? props.hasAccess : true;
});

const parsedChapter = computed(() =>
  parseChapterForDisplay(
    props.chapter,
    "med",
    props.chapterKind,
    props.volumeKind,
  ),
);

const {
  isChapterRead,
  markChaptersRead,
  markChaptersUnread,
  databaseRefreshIndex,
} = useReadMarkers(props.titleId);

const isRead = ref(false);

const { refresh } = useLazyAsyncData(
  async () => (isRead.value = await isChapterRead(props.chapter.id)),
  { watch: [databaseRefreshIndex] },
);
const toggleRead = () => {
  const wasRead = isRead.value;
  isRead.value = !isRead.value;
  if (wasRead) markChaptersUnread([props.chapter.id]).then(() => refresh());
  else markChaptersRead([props.chapter.id]).then(() => refresh());
};

const { data: statistics, isPending: statsLoading } = useQuery(
  useChapterStats(props.chapter.id),
);
const totalLikes = computed(() =>
  sum(Object.values(statistics.value?.attributes.reactions ?? {})),
);

const mappedLocale = computed(() =>
  reverseLocaleMap(parsedChapter.value.language),
);

const formatNumber = useNumberFormatter(locale);
const formatDate = useTimeAgo(locale);

const publishAt = formatDate.value(
  new Date(parsedChapter.value.publishAt ?? 0),
  "mini",
);

async function showUserHasNoAccessToChapterModal(): Promise<
  "subscribe" | "cancel"
> {
  return (
    (await appStore?.prompt(
      translate("components.chapterCard.promptSubscriptionRequiredText"),
      {
        icon: "okay",
        forceCloseKey: "cancel",
        buttons: {
          cancel: {
            buttonType: "secondary",
            buttonText: translate(
              "components.chapterCard.promptSubscriptionRequiredButtonCancel",
            ),
          },
          subscribe: {
            buttonType: "primary",
            buttonText: translate(
              "components.chapterCard.promptSubscriptionRequiredButtonSubscribe",
            ),
          },
        },
      },
    )) ?? "cancel"
  );
}

async function showUserHasNoAccessToChapterModalNew() {
  if (!props.titleId || !props.organization) {
    await showUserHasNoAccessToChapterModal();
  } else {
    await appStore?.prompt(
      ModalGatingNoAccess,
      { large: true },
      {
        titleId: props.titleId,
        orgId: props.organization.id,
        orgSlug: props.organization.attributes.slug,
      },
    );
  }
  return "";
}

async function promptUserSubscription() {
  const result = await showUserHasNoAccessToChapterModalNew();
  if (result !== "subscribe") return;

  const org = await getChapterOrganization();
  navigateTo({
    path: `${linkTo(org)}/subscriptions`,
    query: {
      returnTo: encodeURIComponent(route.fullPath),
    },
  });
}

async function getChapterOrganization() {
  if (props.organization) return props.organization;
  if (!isRelationship(props.chapter)) {
    const org = getRelationship(props.chapter, "organization");
    if (isExpanded(org)) return org;
    return await Organization.get(org.id, []);
  }
  const chapter = await Chapter.get(props.chapter.id, ["organization"]);
  return getRelationship(chapter, "organization", true);
}

const userNotMature = computed(
  () => props.mature && !settingsStore?.settings.platform.matureContentEnabled,
);

async function handleChapterClick(e: Event) {
  if (props.noLink) return;

  if (!isUnlocked.value) {
    e.preventDefault();
    e.stopPropagation();
    if (!loggedIn.value) {
      appStore?.openLoginRequiredModal();
      return;
    }
    await promptUserSubscription();
  }

  if (userNotMature.value) {
    e.preventDefault();
    e.stopPropagation();

    const result = await promptMature(false);
    if (result === "cancel") return;

    if (loggedIn.value)
      return navigateTo({
        path: `/${mappedLocale}/settings/account-preferences`,
        query: {
          returnTo: encodeURIComponent(route.fullPath),
        },
      });
    return authStore?.login({
      redirectBackTo: route.fullPath,
      locale: mappedLocale.value,
    });
  }

  emit("navigate", props.chapter.id);
}
</script>

<style scoped lang="postcss">
.chapter-card__wrap {
  @apply relative rounded-md;

  &:not(.transparent) {
    @apply transition-colors duration-75
    bg-neutral-100 dark:bg-neutral-800
    canhover:hover:bg-neutral-200 canhover:dark:hover:bg-neutral-700
    active:bg-neutral-200 dark:active:bg-neutral-700;
  }

  .chapter-card__more-options {
    @apply canhover:hidden;
  }

  &:hover .chapter-card__more-options,
  &:focus-within .chapter-card__more-options {
    display: initial;
  }
}

.chapter-card {
  height: 4rem;
  display: grid;
  grid-template-columns: 6rem 1fr min-content min-content min-content;
  grid-template-rows: min-content auto auto min-content;
  /*
  grid-template-areas:
    "preview title    title   title   spacer3"
    "preview spacer1  spacer1 spacer1 spacer3"
    "preview spacer2  spacer2 spacer2 spacer3"
    "preview org      stats   stats   spacer3"
   */
  grid-template-areas:
    "preview title    title   title   spacer3"
    "preview spacer1  spacer1 spacer1 spacer3"
    "preview spacer2  spacer2 spacer2 spacer3"
    "preview stats    stats   stats   spacer3";

  &.context {
    /*
    grid-template-areas:
      "preview title    title   options spacer3"
      "preview spacer1  spacer1 spacer1 spacer3"
      "preview spacer2  spacer2 spacer2 spacer3"
      "preview org      stats   stats   spacer3"
     */
    grid-template-areas:
      "preview title    title   options spacer3"
      "preview spacer1  spacer1 spacer1 spacer3"
      "preview spacer2  spacer2 spacer2 spacer3"
      "preview stats    stats   stats   spacer3";
  }

  &:not(.small, .x-small) {
    @media screen(sm) {
      height: 4.5rem;
      grid-template-columns: 6.75rem 1fr min-content min-content min-content;
      @apply text-base;
    }
  }

  &.x-small {
    height: 3rem;
    grid-template-columns: 4.5rem 1fr min-content min-content min-content;
  }

  .x-small & {
    @media not screen(sm) {
      height: 3rem;
      grid-template-columns: 4.5rem 1fr min-content min-content min-content;
    }
  }

  @apply overflow-hidden text-sm;
}

.chapter-card__preview {
  grid-area: preview;
}

.chapter-card__title {
  grid-area: title;
  word-break: break-word;
  @apply font-bold pr-5;
}

.chapter-card__org {
  grid-area: org;
  @apply flex items-center gap-2 text-sm w-full;
}

.chapter-card__options {
  grid-area: options;
  @apply w-6 h-6;
}

.chapter-card__stats {
  & > span {
    @apply flex items-center space-x-1;
  }
}
</style>
