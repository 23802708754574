<template>
  <div class="space-y-4">
    <NamiLoading
      v-if="orgTiersPending || gatingConditionsStatus === 'pending'"
    />
    <template v-else-if="orgTiers && gatingConditions && unlockingCondition">
      <AsyncImage
        :src="getAbsoluteAssetLink(`nami/stickers/okay.png`)"
        class="w-48 h-48 mx-auto"
      />
      <h2
        class="font-medium text-lg sm:text-xl text-center dark:text-neutral-50"
      >
        <template
          v-if="
            unlockingCondition.attributes.strategy === 'follows_organization'
          "
        >
          {{ $t("components.modalGatingNoAccess.needToFollowText") }}
        </template>
        <template v-else>
          {{ $t("components.modalGatingNoAccess.needToSubscribeText") }}
        </template>
      </h2>
      <div class="flex space-x-2 justify-end">
        <NamiButton
          button-type="secondary"
          text
          :href="linkTo('title', titleId)"
        >
          {{ $t("components.modalGatingNoAccess.buttonBackText") }}
        </NamiButton>
        <NamiButton
          v-if="!noDismissButton"
          button-type="secondary"
          text
          @button-click="$emit('resolved')"
        >
          {{ $t("components.modalGatingNoAccess.buttonDismissText") }}
        </NamiButton>
        <NamiButton
          v-if="
            unlockingCondition.attributes.strategy === 'follows_organization'
          "
          button-type="primary"
        >
          {{ $t("components.modalGatingNoAccess.buttonFollowText") }}
        </NamiButton>
        <NamiButton
          v-else
          button-type="primary"
          :href="unlockingActionLink ?? undefined"
        >
          {{ $t("components.modalGatingNoAccess.buttonSubscribeText") }}
        </NamiButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  Gating,
  type GatingConditionEntity,
  type GatingStrategyParamMap,
} from "~/src/api";
import { useAsyncOrgTiers } from "~/composables/async/tiers";

const props = defineProps<{
  titleId: string;
  orgId: string;
  orgSlug: string;
  noDismissButton?: boolean;
}>();

const emit = defineEmits<{
  (e: "resolved"): void;
}>();

const asyncOrgTiers = useAsyncOrgTiers(computed(() => props.orgId));
const orgTiers = computed(() => asyncOrgTiers.value.data.value?.data);
const orgTiersPending = computed(() => asyncOrgTiers.value.pending.value);

const {
  status: gatingConditionsStatus,
  data: gatingConditions,
  error: gatingConditionsError,
} = useAsyncData(async () => {
  const conditions = await Gating.getConditions("title", props.titleId);
  return conditions.data;
});

const unlockingCondition = computed(() => {
  if (!gatingConditions.value) return null;

  const unlockingCondition = gatingConditions.value.find(
    (c) =>
      c.attributes.strategy === "subscribed_to_organization" ||
      c.attributes.strategy === "subscribed_tier_amount" ||
      c.attributes.strategy === "subscribed_tier_ids" ||
      c.attributes.strategy === "follows_organization",
  );

  if (!unlockingCondition) return null;

  return unlockingCondition;
});

const firstUnlockingTier = computed(() => {
  if (!unlockingCondition.value) return null;
  if (!orgTiers.value) return null;

  orgTiers.value
    .sort((a, b) => a.attributes.minAmounts.USD! - b.attributes.minAmounts.USD!)
    .at(0) ?? null;

  const unlockingStrategy = unlockingCondition.value.attributes.strategy;

  switch (unlockingStrategy) {
    case "subscribed_tier_ids": {
      const unlockingTierIds = getTierIdsFromCondition(
        unlockingCondition.value,
      );
      if (!unlockingTierIds) return null;
      const foundTiers = orgTiers.value.filter((tier) =>
        unlockingTierIds.includes(tier.id),
      );
      return foundTiers.at(0) ?? null;
    }

    case "subscribed_to_organization": {
      return orgTiers.value.at(0) ?? null;
    }

    case "subscribed_tier_amount": {
      const { amount, comparison } = getMinAmountAndComparisonFromRules(
        unlockingCondition.value,
      );
      const firstUnlockingTier = orgTiers.value.find((tier) =>
        amountSatisfiesConditionComparison(
          tier.attributes.minAmounts.USD!,
          amount,
          comparison,
        ),
      );
      return firstUnlockingTier ?? null;
    }

    case "follows_organization":
    default:
      return null;
  }
});

const unlockingActionLink = computed(() => {
  if (!unlockingCondition.value) return null;

  if (unlockingCondition.value.attributes.strategy === "follows_organization") {
    return linkTo("org", props.orgSlug);
  }

  if (!firstUnlockingTier.value) return null;

  return `${linkTo("org", props.orgSlug)}/subscriptions?highlightTier=${
    firstUnlockingTier.value.id
  }&highlightText=${encodeURIComponent("Unlocks chapters!")}`;
});

function getTierIdsFromCondition(condition: GatingConditionEntity) {
  const params = condition.attributes.parameters;
  if (!("tierItemIds" in params)) return null;
  return params.tierItemIds;
}

function getMinAmountAndComparisonFromRules(
  condition: GatingConditionEntity,
): GatingStrategyParamMap["subscribed_tier_amount"] {
  const finalRuleParams: GatingStrategyParamMap["subscribed_tier_amount"] = {
    amount: 0,
    comparison: "gte",
  };
  const params = condition.attributes.parameters;
  if (!("amount" in params)) return finalRuleParams;
  return { amount: params.amount, comparison: params.comparison };
}

function amountSatisfiesConditionComparison(
  amount: number,
  minAmount: number,
  comparison: GatingStrategyParamMap["subscribed_tier_amount"]["comparison"],
) {
  switch (comparison) {
    case "eq":
      return amount === minAmount;
    case "gt":
      return amount > minAmount;
    case "gte":
      return amount >= minAmount;
    case "lt":
      return amount < minAmount;
    case "lte":
      return amount <= minAmount;
    case "neq":
      return amount !== minAmount;
    default:
      return amount === minAmount;
  }
}
</script>
